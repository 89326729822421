import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthUtils } from './auth.utils';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { RolesService } from 'app/core/service/role.service';
import { UserService } from 'app/core/user/user.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const rolesService = inject(RolesService);
  const userService = inject(UserService);
  const router = inject(Router);

  // Clone the request object
  let newReq = req.clone();

  // Request
  //
  // If the access token didn't expire, add the Authorization header.
  // We won't add the Authorization header if the access token expired.
  // This will force the server to return a "401 Unauthorized" response
  // for the protected API routes which our response interceptor will
  // catch and delete the access token from the local storage while logging
  // the user out from the app.
  if (
    authService.accessToken &&
    !AuthUtils.isTokenExpired(authService.accessToken)
  ) {
    newReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        'Bearer ' + authService.accessToken,
      ),
    });
  }

  // Response
  return next(newReq).pipe(
    catchError((error) => {
      // Catch "401 Unauthorized" responses
      if (
        error instanceof HttpErrorResponse &&
        !req.url.includes('/sign-in') &&
        error.status === 401
      ) {
        // Sign out
        return authService.signInUsingToken().pipe(
          switchMap((response: any) => {
            // If there is an error response, the access token must have expired.
            // Try to re-sign in the user with their credentials
            if (!response) {
              return of(response);
            }

            // Store the access token in the local storage
            authService.accessToken = response.access_token;
            authService.refreshToken = response.refresh_token;

            const decoded = AuthUtils.decodeToken(response.access_token);

            rolesService.update(decoded.roles);

            // Set the authenticated flag to true
            authService.authenticated = true;

            // Store the user on the user service
            userService.user = response.user;

            newReq.headers.set(
              'Authorization',
              `Bearer ${response.access_token}`,
            );
            return next(newReq);
          }),
          catchError(() => {
            // Return false
            authService.signOut();
            router.navigate(['/sign-in']);
            return throwError(error);
          }),
        );
      }

      return throwError(error);
    }),
  );
};
