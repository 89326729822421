import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'index' },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('app/pages/private/private.routing'),
  },

  // Private routes for authenticated users

  // Auth routes for authenticated users

  {
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    path: 'terms-of-use',
    loadChildren: () => import('app/pages/public/terms/terms.routing'),
  },
  {
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    path: 'register',
    loadChildren: () => import('app/pages/auth/sign-up/sign-up.routing'),
  },
  {
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    path: 'forgot-password',
    loadChildren: () =>
      import('app/pages/auth/forgot-password/forgot-password.routing'),
  },
  {
    path: 'sign-in',
    loadChildren: () => import('app/pages/auth/sign-in/sign-in.routing'),
  },
  {
    path: 'confirmation',
    loadChildren: () =>
      import('app/pages/auth/confirmation/confirmation.routing'),
  },
  {
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    path: 'confirmation-required',
    loadChildren: () =>
      import(
        'app/pages/auth/confirmation-required/confirmation-required.routing'
      ),
  },
  {
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    path: 'reset-password',
    loadChildren: () =>
      import('app/pages/auth/reset-password/reset-password.routing'),
  },
  {
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    path: 'sign-out',
    loadChildren: () => import('app/pages/auth/sign-out/sign-out.routing'),
  },
  {
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    path: 'unlock-session',
    loadChildren: () =>
      import('app/pages/auth/unlock-session/unlock-session.routing'),
  },
];
